import Vue from "vue";

/* eslint-disable */
import Router from "vue-router";

import dotenv from "dotenv";
import service from "./services";
import jwt from "jsonwebtoken";
/*import service from './services'
import jwt from "jsonwebtoken";*/
import NProgress from "nprogress";
import "../node_modules/nprogress/nprogress.css";

dotenv.config();

Vue.use(Router);

const meta = {
  title: process.env.VUE_APP_SITE_TITLE,
  metaTags: [
    {
      name: "description",
      content: process.env.VUE_APP_SITE_DESC,
    },
    {
      property: "og:description",
      content: process.env.VUE_APP_SITE_DESC,
    },
  ],
};

const routes = [
  {
    path: "/",
    name: "index",
    component: () => import("./components/Login.vue"),
    meta: meta,
  },
  {
    path: "/dashboard",
    name: "dashboard",
    beforeEnter: function(to, from, next) {
      isAuth(to, next);
    },
    component: () => import("./components/DashBoard.vue"),
    meta: meta,
  },
  {
    path: "/cartaoVirtual",
    name: "cartaoVirtual",
    beforeEnter: function(to, from, next) {
      isAuth(to, next);
    },
    component: () => import("./components/cartaoVirtual"),
    meta: meta,
  },
  {
    path: "/favoritos",
    name: "favoritos",
    beforeEnter: function(to, from, next) {
      isAuth(to, next);
    },
    component: () => import("./components/favoritos"),
    meta: meta,
  },
  {
    path: "/sobre",
    name: "sobre",
    beforeEnter: function(to, from, next) {
      isAuth(to, next);
    },
    component: () => import("./components/sobre"),
    meta: meta,
  },
  {
    path: "/ajuda",
    name: "ajuda",
    beforeEnter: function(to, from, next) {
      isAuth(to, next);
    },
    component: () => import("./components/ajuda"),
    meta: meta,
  },
  {
    path: "/econometro",
    name: "Econometro",
    beforeEnter: function(to, from, next) {
      isAuth(to, next);
    },
    component: () => import("./components/Econometro"),
    meta: meta,
  },
];

const router = new Router({
  routes,
  mode: "history",
  scrollBehavior: (to) => {
    if (to.hash) {
      return {
        selector: to.hash,
      };
    } else {
      return {
        x: 0,
        y: 0,
      };
    }
  },
});

function isAuth(to, next) {
  const token = localStorage.getItem("user-token");
  if (token) {
    const decoded = jwt.decode(token);
    console.log(decoded);
    service
      .validaToken(token, "Login", "ValidateToken")
      .then((res) => {
        console.log(res);
        if (res.data.result == false) {
          next(`/?idEmpresa=${decoded.id_empresa}`);
        } else {
          next();
        }
      })
      .catch(() => {
        if (decoded) next(`/?idEmpresa=${decoded.id_empresa}`);
        else next(`/`);
      });
  } else {
    next(`/`);
  }
}

router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);
  //const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) document.title = nearestWithTitle.meta.title;

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(
    document.querySelectorAll("[data-vue-router-controlled]")
  ).map((el) => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags
    .map((tagDef) => {
      const tag = document.createElement("meta");

      Object.keys(tagDef).forEach((key) => {
        tag.setAttribute(key, tagDef[key]);
      });

      // We use this to track which meta tags we create, so we don't interfere with other ones.
      tag.setAttribute("data-vue-router-controlled", "");

      return tag;
    })
    // Add the meta tags to the document head.
    .forEach((tag) => document.head.appendChild(tag));

  next();
});

router.beforeResolve((to, from, next) => {
  if (to.name) {
    NProgress.start();
  }
  next();
});

router.afterEach((to, from) => {
  NProgress.done();
});

export default router;
